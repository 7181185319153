import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Sidebar = ({ onSelectPage, onMainContent }) => {
  const [pages, setPages] = useState([]);
  const blogId = '1555415857002703612';  // Replace with your Blogger Blog ID
  const apiKey = 'AIzaSyBCuUWquNrat7XA0oeYfexifEjP5V7wRGE';  // Replace with your API key
  const [activeButton, setActiveButton] = useState(null);

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/blogger/v3/blogs/${blogId}/pages?key=${apiKey}`
        );
        setPages(response.data.items);
      } catch (error) {
        console.error('Error fetching pages:', error);
      }
    };
    fetchPages();
  }, []);

  const handleClick = (pageId) => {
    setActiveButton(pageId);
    if (pageId === 'main') {
      onMainContent();
    } else {
      onSelectPage(pageId);
    }
  };

  return (
    <div className="sidebar">
      <h3>Navegación</h3>
      <ul>
        <li>
          <button 
            onClick={() => handleClick('main')}
            className={activeButton === 'main' ? 'active' : ''}
          >
            Página Principal
          </button>
        </li>
        {pages.map((page) => (
          <li key={page.id}>
            <button 
              onClick={() => handleClick(page.id)}
              className={activeButton === page.id ? 'active' : ''}
            >
              {page.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
