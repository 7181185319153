import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const CalendarContainer = styled.div`
  background: white;
  border-radius: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.06);
  width: 100%;
  overflow: hidden;
`;

const CalendarHeader = styled.div`
  background: linear-gradient(135deg, #9d3de9, #8a2be2);
  color: white;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MonthYear = styled.h2`
  margin: 0;
  flex-grow: 1;
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
`;

const NavButton = styled.button`
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  padding: 0.5rem;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: scale(1.05);
  }
`;

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 8px;
  padding: 1.5rem;
  background: white;
`;

const DayCell = styled.div`
  text-align: center;
  padding: 0.75rem;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  position: relative;
  transition: all 0.2s ease;

  ${props => props.$isWeekday && `
    font-weight: 600;
    color: #666;
    font-size: 0.8rem;
    text-transform: uppercase;
  `}

  ${props => props.$isToday && `
    color: #8a2be2;
    font-weight: 600;
    border: 2px solid #8a2be2;
    border-radius: 50%;
    
    &:hover {
      background: rgba(138, 43, 226, 0.1);
    }
  `}

  ${props => props.$isSelected && `
    color: white;
    font-weight: 600;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #9d3de9;
      border-radius: 50%;
      z-index: -1;
    }
  `}

  ${props => props.$isEventDay && !props.$isToday && !props.$isSelected && `
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 215, 0, 0.15);
      border-radius: 50%;
      z-index: -1;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 15%;
      left: 50%;
      transform: translateX(-50%);
      width: 6px;
      height: 6px;
      background: #ffa500;
      border-radius: 50%;
      box-shadow: 0 0 4px rgba(255, 165, 0, 0.5);
    }
  `}

  &:hover {
    ${props => !props.$isWeekday && !props.$isEmpty && `
      transform: scale(1.1);
      cursor: pointer;
      background: rgba(255, 215, 0, 0.2);
      border-radius: 50%;
    `}
  }
`;

const EventsSection = styled.div`
  border-top: 1px solid #f0f0f0;
  padding: 1.5rem;
`;

const EventItem = styled.div`
  border-left: 4px solid #8a2be2;
  padding: 1rem;
  margin: 0.75rem 0;
  background: #f9f5ff;
  border-radius: 8px;
  transition: all 0.2s ease;

  &:hover {
    transform: translateX(4px);
    box-shadow: 0 2px 8px rgba(138, 43, 226, 0.1);
  }

  h4 {
    margin: 0 0 0.5rem 0;
    color: #8a2be2;
  }

  p {
    margin: 0.25rem 0;
    color: #666;
    font-size: 0.9rem;
  }
`;

const AddEventButton = styled.button`
  width: 100%;
  background: #8a2be2;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 0 0 8px 8px;
  cursor: pointer;
  &:hover {
    background: #7825c4;
  }
`;

const EventsSidebar = () => {
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [eventDates, setEventDates] = useState([]);
  const [events, setEvents] = useState([]);

  const getDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    let firstDay = new Date(year, month, 1).getDay();
    
    firstDay = firstDay === 0 ? 6 : firstDay - 1;
    
    const days = Array(firstDay).fill(null);
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(i);
    }
    return days;
  };

  const monthNames = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];

  const weekDays = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];

  const goToPreviousMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const goToNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const isToday = (day) => {
    const today = new Date();
    return day === today.getDate() && 
           currentDate.getMonth() === today.getMonth() && 
           currentDate.getFullYear() === today.getFullYear();
  };

  const handleDateClick = (day) => {
    if (day) {
      const clickedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
      setSelectedDate(clickedDate);

      const dateEvents = events.filter(event => {
        const eventDate = new Date(event.attributes.Date);
        return eventDate.getDate() === day;
      });

      if (dateEvents.length > 0) {
        if (dateEvents.length === 1) {
          navigate(`/agenda/${dateEvents[0].id}`);
        } else {
          navigate('/agenda', { 
            state: { 
              date: clickedDate.toISOString(),
              events: dateEvents
            }
          });
        }
      }
    }
  };

  const isSelected = (day) => {
    if (!selectedDate || !day) return false;
    return day === selectedDate.getDate() && 
           currentDate.getMonth() === selectedDate.getMonth() && 
           currentDate.getFullYear() === selectedDate.getFullYear();
  };

  const isEventDay = (day) => {
    return day && eventDates.includes(day);
  };

  useEffect(() => {
    const fetchEventDates = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      const token = process.env.REACT_APP_API_TOKEN;
      
      try {
        const response = await axios.get(
          `${apiUrl}/api/agendas`, {
            params: {
              'filters[Year][$eq]': currentDate.getFullYear(),
              'pagination[pageSize]': 100,
              'pagination[page]': 1
            },
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        const fetchedEvents = response.data.data;
        const currentMonthEvents = fetchedEvents.filter(event => {
          const eventDate = new Date(event.attributes.Date);
          return eventDate.getMonth() === currentDate.getMonth();
        });

        setEvents(currentMonthEvents);
        setEventDates(currentMonthEvents.map(event => new Date(event.attributes.Date).getDate()));
      } catch (error) {
        console.error("Error fetching event dates:", error);
      }
    };

    fetchEventDates();
  }, [currentDate]);

  return (
    <CalendarContainer>
      <CalendarHeader>
        <NavButton onClick={goToPreviousMonth}>&lt;</NavButton>
        <MonthYear>{monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}</MonthYear>
        <NavButton onClick={goToNextMonth}>&gt;</NavButton>
      </CalendarHeader>

      <CalendarGrid>
        {weekDays.map(day => (
          <DayCell key={day} $isWeekday>
            {day}
          </DayCell>
        ))}
        
        {getDaysInMonth(currentDate).map((day, index) => (
          <DayCell 
            key={index}
            $isToday={isToday(day)}
            $isSelected={isSelected(day)}
            $isEmpty={!day}
            $isEventDay={isEventDay(day)}
            onClick={() => handleDateClick(day)}
            style={{ cursor: isEventDay(day) ? 'pointer' : 'default' }}
          >
            {day}
          </DayCell>
        ))}
      </CalendarGrid>

      {/* <EventsSection>
        <h3>Próximos Eventos</h3>
        <EventItem>
          <h4>Debate Feminista</h4>
          <p>15 de noviembre - 18:00</p>
          <p>Centro Cultural</p>
        </EventItem>
        <EventItem>
          <h4>Taller de Literatura</h4>
          <p>20 de noviembre - 17:30</p>
          <p>Biblioteca Municipal</p>
        </EventItem>
        <EventItem>
          <h4>Charla: Historia del Feminismo</h4>
          <p>25 de noviembre - 19:00</p>
          <p>Sala de Conferencias</p>
        </EventItem>
      </EventsSection> */}

    </CalendarContainer>
  );
};

export default EventsSidebar;
