import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import MainSection from './MainSection';
import PageWrapper from './components/PageWrapper';
import { useParams } from 'react-router-dom';

// Contenedores principales
const ContentAndSidebarLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 2rem;
  width: 100%;
`;

const ComadresYFelpeyoContainer = styled.div`
  width: 100%;
`;

const SidebarContainer = styled.div`
  position: sticky;
  top: 2rem;
  height: fit-content;
  overflow-y: auto;
  max-height: calc(100vh - 4rem);
`;

// Componentes de sección
const PremiosContainer = styled.div`
  background-color: #EFF6FF;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
`;

const FelpeyuContainer = styled.div`
  background-color: #FFF1F2;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
`;

const BabayuContainer = styled.div`
  background-color: #F0FDF4;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
`;

const CancionesContainer = styled.div`
  background-color: #FFF7ED;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
`;

// Componentes de premio
const PremioContainer = styled.div`
  margin-bottom: 2rem;
  padding: 1.5rem;
  background-color: white;
  border-radius: 0.75rem;
`;

const GanadorContainer = styled.div`
  background-color: #FFFBEB;
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 0.75rem;
  border: 2px solid #FCD34D;
`;

const CandidatosContainer = styled.div`
  margin-top: 2rem;
`;

const CandidatosList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// Componentes de candidato
const CandidatoWrapper = styled.div`
  background-color: #F9FAFB;
  padding: 1.5rem;
  margin-top: 1rem;
  border-radius: 0.75rem;
  transition: transform 0.2s;
  &:hover {
    transform: translateY(-0.25rem);
  }
`;

// Componentes de texto
const Title = styled.h1`
  font-size: 2.25rem;
  font-weight: bold;
  color: #1F2937;
  text-align: center;
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1F2937;
  margin-bottom: 1.5rem;
`;

const GanadorTitle = styled.h4`
  font-size: 1.25rem;
  font-weight: 600;
  color: #B45309;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 0.5rem;
`;

const CandidatoTitle = styled.h4`
  font-size: 1.25rem;
  font-weight: 600;
  color: #1F2937;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  font-size: 1.125rem;
  color: #4B5563;
  margin-bottom: 1rem;
`;

const VideoLink = styled.a`
  display: inline-block;
  margin-top: 0.8rem;
  color: #6c5ce7;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;

  &:hover {
    color: #5046c0;
  }
`;

const ImagenContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const ComadresImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 12px;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  
  &:hover {
    transform: scale(1.02);
  }
`;

const NoticiasContainer = styled.div`
  background-color: #f8f9ff;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 16px;
  border: 1px solid rgba(92, 162, 231, 0.1);
`;

const NoticiaItem = styled.div`
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FechaNoticia = styled.span`
  color: #666;
  font-size: 0.9rem;
  margin-right: 1rem;
`;

const TituloNoticia = styled.h4`
  margin: 0.5rem 0;
  color: #333;
  font-size: 1.1rem;
`;

const FuenteNoticia = styled.span`
  color: #888;
  font-size: 0.9rem;
  font-style: italic;
  margin-right: 1rem;
`;

const CancionContainer = styled.div`
  background-color: #fff9f8;
  padding: 2rem;
  margin: 1rem 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h3 {
    color: #333;
    margin-bottom: 1rem;
  }

  p {
    margin: 0.5rem 0;
    line-height: 1.6;
  }
`;

const getHistoricalData = async (year) => {
  if (year >= 2024) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = process.env.REACT_APP_API_TOKEN;
    const url = `${apiUrl}/api/comadres-y-felpeyos?populate[Premios][populate][candidatos][populate][Articulos][populate]=*&populate[Premios][populate][candidatos][populate]=*&populate[Felpeyu][populate]=*&populate[Babayu][populate]=*&populate[cancion][populate]=*&populate[noticias][populate]=*&populate[Imagenes][populate]=*`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      // Modificar las URLs de las imágenes para incluir la URL base de la API
      const data = response.data.data[0].attributes;
      if (data.Imagenes && data.Imagenes.data) {
        data.Imagenes.data = data.Imagenes.data.map(imagen => ({
          ...imagen,
          attributes: {
            ...imagen.attributes,
            url: `${apiUrl}${imagen.attributes.url}`
          }
        }));
      }
      
      return data;
    } catch (error) {
      throw new Error(`Error al cargar datos de la API: ${error.message}`);
    }
  } else {
    try {
      const response = await fetch(`/json/f${year}.json`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      
      // Modificar las rutas de las imágenes si existen
      if (data.Imagenes && data.Imagenes.data) {
        data.Imagenes.data = data.Imagenes.data.map(imagen => {
          const ruta = imagen.attributes.url;
          
          if (ruta.startsWith('../') || ruta.startsWith('../../')) {
            let nuevaRuta = ruta;
            
            // Manejar los diferentes tipos de rutas
            if (ruta.startsWith('../../dibujos/')) {
              nuevaRuta = `/dibujos/${ruta.split('../../dibujos/')[1]}`;
            } else if (ruta.startsWith('../comadres/')) {
              nuevaRuta = `/comadres/${ruta.split('../comadres/')[1]}`;
            } else if (ruta.startsWith('../felpeyus/')) {
              nuevaRuta = `/felpeyus/${ruta.split('../felpeyus/')[1]}`;
            } else if (ruta.startsWith('../otros/')) {
              nuevaRuta = `/otros/${ruta.split('../otros/')[1]}`;
            }
            
            return {
              ...imagen,
              attributes: {
                ...imagen.attributes,
                url: nuevaRuta
              }
            };
          }
          return imagen;
        });
      }
      
      return data;
    } catch (error) {
      throw new Error(`Error al cargar datos del JSON: ${error.message}`);
    }
  }
};


// Función para modificar las rutas de las imágenes
const modificarRutasImagenes = (data) => {
  // No modificar si los datos vienen de la API
  if (data.data && Array.isArray(data.data)) {
    return data;
  }

  // Modificar las rutas locales
  const nuevasImagenes = data.Imagenes.data.map(imagen => {
    const ruta = imagen.attributes.url;
    
    // Verificar si es una ruta local
    if (ruta.startsWith('../') || ruta.startsWith('../../')) {
      return {
        ...imagen,
        attributes: {
          ...imagen.attributes,
          url: `/public${ruta.replace('../', '/')}`
        }
      };
    }
    
    return imagen;
  });

  return {
    ...data,
    Imagenes: {
      data: nuevasImagenes
    }
  };
};

const ComadresYFelpeyo = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const { year } = useParams(); // Obtiene el año de la URL

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Usa el año de los parámetros, o el año actual como respaldo
        const yearToFetch = year || new Date().getFullYear();
        const historicalData = await getHistoricalData(yearToFetch);
        setData(historicalData);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [year]);
  const renderText = (content) => {
    if (!content) return null;
    
    // Handle plain strings
    if (typeof content === 'string') return content;
    
    // Handle arrays of content blocks
    if (Array.isArray(content)) {
      return content.map((block, blockIndex) => {
        if (!block || typeof block !== 'object') return null;
  
        // Handle paragraph blocks
        if (block.type === 'paragraph') {
          return (
            <p key={blockIndex} className="mb-2">
              {block.children?.map((child, childIndex) => {
                if (!child) return null;
  
                // Handle plain text
                if (child.type === 'text') {
                  return <span key={`text-${childIndex}`}>{child.text}</span>;
                }
  
                // Handle links
                if (child.type === 'link' && child.url) {
                  return (
                    <VideoLink 
                      key={`link-${childIndex}`}
                      href={child.url.trim()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {child.children?.[0]?.text || 'Ver video'}
                    </VideoLink>
                  );
                }
  
                return null;
              })}
            </p>
          );
        }
  
        return null;
      });
    }
    
    // Handle single content object
    if (content.type === 'paragraph') {
      return renderText([content]);
    }
  
    return null;
  };
  const renderDescription = (description) => {
    if (!description) return null;
    return <Description>{renderText(description)}</Description>;
  };

  const renderCandidato = (candidato, parentKey) => (
    <CandidatoWrapper key={`${parentKey}-candidato-${candidato.id || Math.random()}`}>
      <CandidatoTitle>
        {candidato.nombre || candidato.Nombre}
      </CandidatoTitle>
      {renderDescription(candidato.descripcion || candidato.Description || candidato.Descripcion)}
      
      {candidato.Articulos?.map((articulo, index) => (
        <div key={`${parentKey}-articulo-${index}`}>
          {articulo.Fuente && (
            <Description>{articulo.Fuente}</Description>
          )}
          <div>
            {articulo.URL && (
              <VideoLink 
                href={articulo.URL.trim()}
                target="_blank"
                rel="noopener noreferrer"
              >
                Video 1
              </VideoLink>
            )}
            {articulo.URL2 && (
              <>
                {" "}
                <VideoLink 
                  href={articulo.URL2.trim()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Video 2
                </VideoLink>
              </>
            )}
          </div>
        </div>
      ))}
    </CandidatoWrapper>
  );

  const renderPremio = (premio, index, tipo) => {
    // Verificar si el premio existe y tiene contenido significativo
    if (!premio || 
        (!premio.recipient )) {
      return null;
    }

    // Solo renderizar si hay contenido
    const hasContent = premio.recipient || 
                      (premio.candidatos && premio.candidatos.length > 0);
    
    if (!hasContent) return null;

    return (
      <PremioContainer key={`${tipo}-${premio.id || index}`}>
        {premio.recipient && (
          <GanadorContainer>
            <GanadorTitle>Ganador</GanadorTitle>
            <Description>{premio.recipient}</Description>
            {(premio.descripcion || premio.description) && (
              <Description>
                {premio.descripcion || premio.description}
              </Description>
            )}
          </GanadorContainer>
        )}
        
        {premio.candidatos && premio.candidatos.length > 0 && (
          <CandidatosContainer>
            <SectionTitle>Finalistas</SectionTitle>
            <CandidatosList>
              {premio.candidatos.map((candidato, idx) => 
                renderCandidato(candidato, `${tipo}-${idx}`)
              )}
            </CandidatosList>
          </CandidatosContainer>
        )}
      </PremioContainer>
    );
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data) {
    return <div>Cargando...</div>;
  }

  return (
    <PageWrapper>
      <ContentAndSidebarLayout>
        <ComadresYFelpeyoContainer>
          <Title>{data.Title}</Title>
          
          {data.Premios && data.Premios.length > 0 && (
            <PremiosContainer>
              {data.Premios.map((premio, index) => (
                <div key={index}>
                  {/* Título de la sección */}
                  <SectionTitle>
                    {premio.title}
                  </SectionTitle>

                  {/* Contenedor del premio */}
                  <PremioContainer>
                    <GanadorContainer>
                      <GanadorTitle>
                        {premio.title === "COMADRE DE ORO ESPECIAL" ? "Premiada" : "Ganadora"}
                      </GanadorTitle>
                      <Description>
                        {premio.recipient || premio.nombre}
                      </Description>
                      {(premio.descripcion || premio.description) && (
                        <Description>
                          {premio.descripcion || premio.description}
                        </Description>
                      )}
                    </GanadorContainer>

                    {/* Renderizar candidatos solo si existen y no es un premio especial */}
                    {premio.candidatos && premio.candidatos.length > 0 && premio.title !== "COMADRE DE ORO ESPECIAL" && (
                      <CandidatosContainer>
                        <SectionTitle>Finalistas</SectionTitle>
                        <CandidatosList>
                          {premio.candidatos.map((candidato, idx) => 
                            renderCandidato(candidato, `premio-${index}-${idx}`)
                          )}
                        </CandidatosList>
                      </CandidatosContainer>
                    )}
                  </PremioContainer>
                </div>
              ))}
            </PremiosContainer>
          )}

          {data.Felpeyu && data.Felpeyu.some(premio => premio.recipient || (premio.candidatos && premio.candidatos.length > 0)) && (
            <FelpeyuContainer>
              <SectionTitle>Felpeyu</SectionTitle>
              {Array.isArray(data.Felpeyu) && data.Felpeyu.map((premio, index) => 
                renderPremio(premio, index, 'felpeyu')
              )}
            </FelpeyuContainer>
          )}

          {data.Babayu && (
            <BabayuContainer>
              <SectionTitle>Babayu</SectionTitle>
              {Array.isArray(data.Babayu) ? (
                // Si es un array, mapeamos los premios
                data.Babayu.map((premio, index) => 
                  renderPremio(premio, index, 'babayu')
                )
              ) : (
                // Si es un objeto único, lo renderizamos directamente
                renderPremio(data.Babayu, 0, 'babayu')
              )}
            </BabayuContainer>
          )}

          {data.cancion && data.cancion.title && (
            <CancionesContainer>
              <SectionTitle>Canciones</SectionTitle>
              <h3>{data.cancion.title}</h3>
              {data.cancion.recipient && <p>Autor: {data.cancion.recipient}</p>}
              
              {data.cancion.description && (
                <CancionContainer>
                  {Array.isArray(data.cancion.description) && 
                    data.cancion.description.map((linea, index) => (
                      <p key={index}>
                        {typeof linea === 'string' 
                          ? linea.split('\n').map((fragment, i) => (
                              <React.Fragment key={i}>
                                {fragment}
                                <br />
                              </React.Fragment>
                            ))
                          : linea.children?.[0]?.text.split('\n').map((fragment, i) => (
                              <React.Fragment key={i}>
                                {fragment}
                                <br />
                              </React.Fragment>
                            ))
                        }
                      </p>
                    ))
                  }
                </CancionContainer>
              )}
            </CancionesContainer>
          )}

          {data.noticias && data.noticias.length > 0 && (
            <NoticiasContainer>
              <SectionTitle>Noticias</SectionTitle>
              {data.noticias.map((noticia, index) => (
                <NoticiaItem key={index}>
                  <FechaNoticia>{noticia.fecha}</FechaNoticia>
                  <TituloNoticia>{noticia.titulo}</TituloNoticia>
                  <FuenteNoticia>{noticia.fuente}</FuenteNoticia>
                  {noticia.enlace && (
                    <a href={noticia.enlace} target="_blank" rel="noopener noreferrer">
                      Ver noticia
                    </a>
                  )}
                </NoticiaItem>
              ))}
            </NoticiasContainer>
          )}
        </ComadresYFelpeyoContainer>
        
        <SidebarContainer>
          <ImagenContainer>
            {data.Imagenes?.data?.map((imagen, index) => (
              <ComadresImage 
                key={imagen.id}
                src={imagen.attributes.url} 
                alt={imagen.attributes.alternativeText}
              />
            ))}
          </ImagenContainer>
        </SidebarContainer>
      </ContentAndSidebarLayout>
    </PageWrapper>
  );
};

export default ComadresYFelpeyo;
