import React from 'react';
import styled from 'styled-components';

const MemoriasContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h1`
  color: #8a2be2;
  text-align: center;
  margin-bottom: 2rem;
`;

const MemoriasGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
`;

const MemoriaCard = styled.div`
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const MemoriaTitle = styled.h3`
  color: #8a2be2;
  margin-bottom: 1rem;
`;

const MemoriaDescription = styled.p`
  color: #666;
  margin-bottom: 1rem;
`;

const DownloadButton = styled.a`
  display: inline-block;
  background-color: #cc99ff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #8a2be2;
  }
`;

const Memorias = () => {
  const memorias = [
    {
        id: 1,
        title: "Memoria 2023",
        description: "Resumen de actividades y logros del año 2022",
        url: "/memorias/Memoria actividades 2023.pdf"
      },
    
    {
      id: 2,
      title: "Memoria 2022",
      description: "Resumen de actividades y logros del año 2022",
      url: "/memorias/Memoria actividades 2022.pdf"
    },
    {
      id: 3,
      title: "Memoria 2021",
      description: "Resumen de actividades y logros del año 2021",
      url: "/memorias/Memoria actividades 2021.pdf"
    },
    {
      id: 4,
      title: "Memoria 2020",
      description: "Resumen de actividades y logros del año 2020",
      url: "/memorias/Memoria actividades 2020.pdf"
    },
    {
      id: 5,
      title: "Memoria 2019",
      description: "Resumen de actividades y logros del año 2019",
      url: "/memorias/Memoria actividades 2019.pdf"
    },
    {
      id: 6,
      title: "Memoria 2018",
      description: "Resumen de actividades y logros del año 2018",
      url: "/memorias/Memoria actividades 2018.pdf"
    },
    {
        id: 7,
        title: "Memoria 2017",
        description: "Resumen de actividades y logros del año 2021",
        url: "/memorias/Memoria actividades 2017.pdf"
      },
      {
        id: 8,
        title: "Memoria 2016",
        description: "Resumen de actividades y logros del año 2020",
        url: "/memorias/Memoria actividades 2016.pdf"
      },
      {
        id: 9,
        title: "Memoria 2015",
        description: "Resumen de actividades y logros del año 2019",
        url: "/memorias/Memoria actividades 2015.pdf"
      },
      {
        id: 10,
        title: "Memoria 2014",
        description: "Resumen de actividades y logros del año 2018",
        url: "/memorias/Memoria actividades 2014.pdf"
      }
  ];

  return (
    <MemoriasContainer>
      <Title>Memorias Anuales</Title>
      <MemoriasGrid>
        {memorias.map((memoria) => (
          <MemoriaCard key={memoria.id}>
            <MemoriaTitle>{memoria.title}</MemoriaTitle>
            <MemoriaDescription>{memoria.description}</MemoriaDescription>
            <DownloadButton 
              href={memoria.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ver Memoria
            </DownloadButton>
          </MemoriaCard>
        ))}
      </MemoriasGrid>
    </MemoriasContainer>
  );
};

export default Memorias; 