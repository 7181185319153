import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams, useNavigate } from 'react-router-dom';

const AgendaContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background: #f8f9fa;
`;

const YearNavigation = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 3rem;
  padding: 1rem;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
`;

const YearButton = styled.button`
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 25px;
  background: ${props => props.active ? '#8a2be2' : '#fff'};
  color: ${props => props.active ? '#fff' : '#333'};
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }
`;

const YearSection = styled(motion.div)`
  margin-bottom: 3rem;
`;

const EventCard = styled(motion.div)`
  background: white;
  border-radius: 15px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const EventHeader = styled.div`
  margin-bottom: 1.5rem;
`;

const EventTitle = styled.h2`
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 0.5rem;
`;

const EventSubtitle = styled.h3`
  font-size: 1.4rem;
  color: #666;
  margin-bottom: 1rem;
`;

const EventContent = styled.div`
  display: flex;
  gap: 2rem;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const EventDetails = styled.div`
  flex: 1;
`;

const EventDetail = styled.p`
  margin: 0.8rem 0;
  color: #444;
  font-size: 1.1rem;
  line-height: 1.6;

  strong {
    color: #8a2be2;
  }
`;

const ImageContainer = styled.div`
  flex: 0 0 300px;
  
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const EventImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const YearTitle = styled.h2`
  color: #ff0000;
  text-align: center;
  margin: 2rem 0;
  font-size: 1.5rem;
`;

const renderContent = (contenido) => {
  if (!contenido) return null;

  // Crear un Set para rastrear contenido único
  const seenContent = new Set();
  
  return contenido.map((item, index) => {
    if (item.type === 'paragraph' && item.children) {
      const contentText = item.children
        .map(child => {
          if (child.type === 'text') {
            return child.text;
          } else if (child.type === 'link') {
            return child.children[0].text;
          }
          return '';
        })
        .join('');

      // Si ya hemos visto este contenido, lo saltamos
      if (seenContent.has(contentText)) {
        return null;
      }
      
      seenContent.add(contentText);

      return (
        <EventDetail key={index}>
          {item.children.map((child, childIndex) => {
            if (child.type === 'text') {
              return <span key={childIndex}>{child.text}</span>;
            } else if (child.type === 'link') {
              return (
                <a 
                  key={childIndex} 
                  href={child.url} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {child.children[0].text}
                </a>
              );
            }
            return null;
          })}
        </EventDetail>
      );
    }
    return null;
  }).filter(Boolean); // Eliminar los elementos null
};

const Agenda = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [eventosPorAño, setEventosPorAño] = useState({});
  const [loading, setLoading] = useState(true);

  // Añadir esta línea para obtener los años de los eventos
  const años = Object.keys(eventosPorAño).map(Number).sort((a, b) => b - a);

  useEffect(() => {
    const fetchEventos = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = process.env.REACT_APP_API_TOKEN;
        let allEventos = [];
        let page = 1;
        const seenEvents = new Set();
        // Si hay un ID, obtener solo ese evento específico
        if (id) {
          const response = await axios.get(
            `${apiUrl}/api/agendas/${id}`,
            {
              headers: { Authorization: `Bearer ${token}` }
            }
          );
          const evento = response.data.data;
          const año = new Date(evento.attributes.Date).getFullYear();
          setEventosPorAño({ [año]: [evento] });
          setSelectedYear(año);
        } else {
          // Si no hay ID, obtener todos los eventos como antes
          const initialResponse = await axios.get(
            `${apiUrl}/api/agendas?pagination[page]=1&pagination[pageSize]=100`,
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );
  
          const totalPages = initialResponse.data.meta.pagination.pageCount;
  
          // Procesar todas las páginas
          for (let currentPage = 1; currentPage <= totalPages; currentPage++) {
            const response = await axios.get(
              `${apiUrl}/api/agendas?pagination[page]=${currentPage}&pagination[pageSize]=100&populate=*`,
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            );
  
            const { data } = response.data;
            allEventos = [...allEventos, ...data];
            
            if (currentPage < totalPages) {
              await new Promise(resolve => setTimeout(resolve, 300));
            }
          }
  
          const eventosOrdenados = allEventos.sort((a, b) => {
            const fechaA = new Date(a.attributes.Date);
            const fechaB = new Date(b.attributes.Date);
            return fechaB - fechaA;
          });
  
          const porAño = eventosOrdenados.reduce((acc, evento) => {
            const año = new Date(evento.attributes.Date).getFullYear();
            if (!acc[año]) {
              acc[año] = [];
            }
            acc[año].push(evento);
            return acc;
          }, {});
          
          setEventosPorAño(porAño);
        
      }
     } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEventos();
  }, [id]); // Añadir id como dependencia

  if (loading) return (
    <AgendaContainer>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        Cargando eventos...
      </motion.div>
    </AgendaContainer>
  );

  return (
    <AgendaContainer>
      {!id && años.length > 0 && ( // Añadir verificación de longitud
        <YearNavigation>
          {años.map((año) => (
            <YearButton
              key={año}
              active={selectedYear === año}
              onClick={() => setSelectedYear(año)}
            >
              {año}
            </YearButton>
          ))}
        </YearNavigation>
      )}

      <AnimatePresence mode='wait'>
        {eventosPorAño[selectedYear] && (
          <YearSection
            key={selectedYear}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
          >
            {eventosPorAño[selectedYear].map((evento) => (
              <EventCard
                key={evento.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                onClick={() => !id && navigate(`/agenda/${evento.id}`)} // Solo navegar si no estamos ya en un evento específico
              >
                <EventHeader>
                  <EventTitle>{evento.attributes.Title}</EventTitle>
                </EventHeader>
                
                <EventContent>
                  {(evento.attributes.ImagePath || evento.attributes.Image?.data?.attributes?.url) && (
                    <ImageContainer>
                      <EventImage 
                        src={
                          evento.attributes.Image?.data?.attributes?.url 
                            ? `${process.env.REACT_APP_API_URL}${evento.attributes.Image.data.attributes.url}`
                            : `/${evento.attributes.ImagePath}`
                        }
                        alt={evento.attributes.Title}
                        onError={(e) => {
                          e.target.style.display = 'none';
                        }}
                      />
                    </ImageContainer>
                  )}
                  <EventDetails>
                    {evento.attributes.Description && (
                      <EventDetail>{evento.attributes.Description}</EventDetail>
                    )}
                    
                    {evento.attributes.Date && (
                      <EventDetail>
                        <strong>Fecha:</strong> {new Date(evento.attributes.Date).toLocaleDateString('es-ES', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </EventDetail>
                    )}
                    
                    {evento.attributes.Location && (
                      <EventDetail>
                        <strong>Lugar:</strong> {evento.attributes.Location}
                      </EventDetail>
                    )}
                    
                    {evento.attributes.Time && (
                      <EventDetail>
                        <strong>Hora:</strong> {evento.attributes.Time}
                      </EventDetail>
                    )}
                    
                    {evento.attributes.Organizer && (
                      <EventDetail>
                        <strong>Organiza:</strong> {evento.attributes.Organizer}
                      </EventDetail>
                    )}
                  </EventDetails>
                </EventContent>
              </EventCard>
            ))}
          </YearSection>
        )}
      </AnimatePresence>
    </AgendaContainer>
  );
};

export default Agenda;
