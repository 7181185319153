import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PageContent = ({ pageId }) => {
  const [page, setPage] = useState(null);
  const blogId = '1555415857002703612';  // Replace with your Blogger Blog ID
  const apiKey = 'AIzaSyBCuUWquNrat7XA0oeYfexifEjP5V7wRGE';  // Replace with your API key

  useEffect(() => {
    if (pageId) {
      const fetchPage = async () => {
        try {
          const response = await axios.get(
            `https://www.googleapis.com/blogger/v3/blogs/${blogId}/pages/${pageId}?key=${apiKey}`
          );
          setPage(response.data);
        } catch (error) {
          console.error('Error fetching page:', error);
        }
      };
      fetchPage();
    }
  }, [pageId]);

  return (
    <div className="page-content">
      {page ? (
        <>
          <h2>{page.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: page.content }} />
        </>
      ) : (
        <p>Select a page to view its content.</p>
      )}
    </div>
  );
};

export default PageContent;
