import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const EstatutosContainer = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const PDFLink = styled.a`
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;

  &:hover {
    background-color: #0056b3;
  }
`;

const PDFViewer = styled.div`
  width: 100%;
  height: 800px;
  margin: 20px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
`;

const Estatutos = () => {
  const [estatutos, setEstatutos] = useState(null);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = process.env.REACT_APP_API_TOKEN;

    axios.get(`${apiUrl}/api/estatutos?populate=*`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        if (response.data.data && response.data.data.length > 0) {
          setEstatutos(response.data.data[0].attributes);
        } else {
          console.error('No se encontraron datos de Estatutos!');
        }
      })
      .catch(error => {
        console.error('Hubo un error al obtener los datos de Estatutos!', error);
      });
  }, []);

  if (!estatutos) {
    return <p>Cargando Estatutos...</p>;
  }

  const pdfUrl = estatutos.Estatutos?.data[0]?.attributes?.url;
  const fullPdfUrl = pdfUrl ? `${process.env.REACT_APP_API_URL}${pdfUrl}` : null;

  return (
    <EstatutosContainer>
      <h2>{estatutos.Title || 'Estatutos'}</h2>
      {fullPdfUrl ? (
        <>
          <PDFViewer>
            <iframe
              src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(fullPdfUrl)}`}
              width="100%"
              height="100%"
              title="Estatutos PDF"
              style={{ border: 'none' }}
              onError={() => {
                alert('Hubo un problema al cargar el PDF. Por favor, intenta nuevamente o descarga el archivo.');
              }}
            >
              <p>Tu navegador no puede mostrar PDFs directamente. 
                <a href={fullPdfUrl} target="_blank" rel="noopener noreferrer">
                  Haz clic aquí para ver el documento
                </a>
              </p>
            </iframe>
          </PDFViewer>
          <p>También puedes descargar los Estatutos aquí:</p>
          <PDFLink href={fullPdfUrl} target="_blank" rel="noopener noreferrer">
            Descargar Estatutos (PDF)
          </PDFLink>
        </>
      ) : (
        <p>No se ha encontrado el archivo PDF de los Estatutos.</p>
      )}
      <p>Creado el: {new Date(estatutos.createdAt).toLocaleDateString()}</p>
      <p>Actualizado el: {new Date(estatutos.updatedAt).toLocaleDateString()}</p>
    </EstatutosContainer>
  );
}

export default Estatutos;