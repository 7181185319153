import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const HemerotecaContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background: #fff;
  box-shadow: 0 0 20px rgba(0,0,0,0.05);
  border-radius: 12px;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #cc99ff;

  img {
    width: 90px;
    height: 60px;
    margin-right: 2rem;
  }

  h2 {
    color: #333;
    font-size: 2rem;
    margin: 0;
  }
`;

const ArticuloContainer = styled.article`
  display: flex;
  align-items: flex-start;
  padding: 1.2rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #f8f4ff;
    transform: translateX(10px);
  }
`;

const FuenteLogo = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 1.5rem;
  object-fit: contain;
  border-radius: 4px;
`;

const DefaultBullet = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 1.5rem;
  background-color: #cc99ff;
  border-radius: 50%;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
  }
`;

const ArticuloInfo = styled.div`
  flex: 1;
`;

const Fecha = styled.span`
  font-weight: 600;
  color: #666;
  margin-right: 0.8rem;
`;

const Contenido = styled.div`
  color: #888;
  margin: 0.5rem 0;
  font-style: italic;
`;

const ArticuloLink = styled.a`
  color: #6633cc;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
  
  &:hover {
    color: #4d1a99;
    text-decoration: underline;
  }
`;

const Fuente = styled.span`
  font-weight: 600;
  color: #666;
  margin-right: 0.8rem;
`;

const Autor = styled.span`
  color: #888;
  font-size: 0.9rem;
  margin-left: 0.8rem;
  
  &::before {
    content: '·';
    margin-right: 0.8rem;
  }
`;

const fuenteLogos = {
  'El País': '/logos/el-pais.svg',
  'elDiario': '/logos/eldiario.svg',
  'El Comercio': '/logos/el-comercio.svg',
  'La Independent': '/logos/la-independiente.svg',
  'MIGIJON': '/logos/migijon.png',
  'MIGIJÓN': '/logos/migijon.png',
  'La Nueva España': '/logos/lne.png',
  'Nueva España': '/logos/lne.png', 
  'RTPA': '/logos/rtpa.svg',
  'Ser Gijón': '/logos/ser.svg',
  'Voz de Asturias': '/logos/voz-asturias.png',
  'La Voz de Asturias': '/logos/voz-asturias.png',
  'Instagram': '/logos/instagram.png',
  'Público': '/logos/publico.png',
  'Europapress': '/logos/europapress.png',
  'elComún.es': '/logos/elcomun.jpg',
  'Crónica Libre': '/logos/cronica-libre.png',
  'InfoLibre': '/logos/infolibre.svg',
  'Agora': '/logos/agora.png',
  'YouTube': '/logos/youtube.png',
  'El Mundo Today': '/logos/elmundotoday.png',
  'AmecoPress': '/logos/amecopress.png',
  'Ser': '/logos/ser.svg',
  'Razón25': '/logos/razon25.png',
  'La Razón': '/logos/razon25.png',
  'Tribuna Feminista': '/logos/tribuna-feminista.png',
  'La sexta': '/logos/lasexta.svg',
  'Europa Press': '/logos/europapress.png',
  'Asturias 24': '/logos/asturias24.png',
  'El digital de Asturias': '/logos/digitalasturias.png',
  'Periodismo humano': '/logos/periodismo-humano.png',
  'nuevaalcarria': '/logos/nuevaalcarria.png',
  'Vientos de cambio justo': '/logos/vientos-cambio-justo.png',
  'Youtube': '/logos/youtube.png',
  'elmundotoday': '/logos/elmundotoday.png',
  'amecopress': '/logos/amecopress.png',
  'Cadena Ser': '/logos/ser.svg',
  'razon25': '/logos/razon25.png',
  'La Vanguardia': '/logos/lavanguardia.png',
  'Onda Cero': '/logos/ondacero.png',
  'IVOOX': '/logos/ivooox.svg',
  'pdf': '/logos/pdf.png',
  'RTVE': '/logos/rtve.png'
};

const articulos = [

  {
    fecha: '07/03/2024',
    fuente: 'Ser Gijón',
    titulo: 'Tertulia 8M en SER Gijón (Paz Fernández Felgueroso, Begoña Piñero y Alba González)',
    url: 'https://cadenaser.com/audio/1709820587414/',
    autor: null
  },
  {
    fecha: '06/03/2024',
    fuente: 'Voz de Asturias',
    titulo: 'Las mujeres del Tren de la Libertad reclaman blindar el aborto en la Constitución como en Francia',
    url: 'https://www.lavozdeasturias.es/noticia/asturias/2024/01/25/comadres-conceden-originales-premios-condenar-machismo-pablo-motos-gana-babayu-rubiales-felpeyu/00031706214699195564913.htm',
    autor: null
  },
  {
    fecha: '05/03/2022',
    fuente: 'El Comercio',
    titulo: 'Paz Fernández-Felgueroso reivindica que la Constitución blinde la ley del aborto',
    url: 'https://www.elcomercio.es/dia-de-la-mujer/paz-fernandez-felguerso-marca-tren-libertad-reto-feminista-20240305185621-nt.html',
    autor: 'Eva Hernández'
  },
  {
    fecha: '05/03/2022',
    fuente: 'El Comercio',
    titulo: 'El Ayuntamiento de Gijón rinde homenaje a las impulsoras del Tren de la Libertad',
    url: 'https://www.elcomercio.es/dia-de-la-mujer/ayuntamiento-gijon-rinde-homenaje-impulsoras-tren-libertad-20240305232523-nt.html',
    autor: 'Soraya Pérez'
  },
  {
    fecha: '03/03/2024',
    fuente: 'El País',
    titulo: 'De la lucha que nació entre flores a la resistencia en redes: una década del despertar social al feminismo',
    url: 'https://elpais.com/sociedad/2024-03-03/de-la-lucha-que-nacio-entre-flores-a-la-resistencia-en-redes-una-decada-del-despertar-social-al-feminismo.html',
    autor: 'Isabel Valdés'
  },
  {
    fecha: '26/02/2024',
    fuente: 'Instagram',
    titulo: 'Floristera jubilada',
    url: 'https://www.instagram.com/reel/C30ba8dIO53/?igsh=ZWF1Z2hxazg1MjYz',
    autor: null
  },
  {
    fecha: '20/02/2022',
    fuente: 'RTPA',
    titulo: 'Tren de la Libertad',
    url: 'https://www.rtpa.es/audio:20al día_1708423778.html',
    autor: 'Asturias al día'
  },
  {
    fecha: '11/02/2024',
    fuente: 'nuevaalcarria',
    titulo: 'El tren de la libertad',
    url: 'https://nuevaalcarria.com/articulos/el-tren-de-la-libertad',
    autor: 'Araceli Matínez Esteban'
  },
  {
    fecha: '04/02/2022',
    fuente: 'El Comercio',
    titulo: 'Los "felpeyos" del balón',
    url: 'https://www.elcomercio.es/sociedad/momentos-historia/felpeyos-balon-20240204010105-nt.html',
    autor: 'Arantza Margolles'
  },
  {
    fecha: '01/02/2024',
    fuente: 'El Comercio',
    titulo: 'Las artífices del Tren de la Libertad reivindican «la fuerza y el ímpetu» de la lucha feminista',
    url: 'https://www.elcomercio.es/gijon/artifices-tren-libertad-reivindican-fuerza-impetu-lucha-20240201001746-nt.html',
    autor: 'Laura Mayordomo'
  },
  {
    fecha: '31/01/2024',
    fuente: 'elDiario',
    titulo: 'El Tren de la Libertad, la primera gran movilización feminista del siglo XXI, cumple diez años',
    url: 'https://www.eldiario.es/asturias/tren-libertad-primera-gran-movilizacion-feminista-siglo-xxi-cumple-diez-anos_1_10878669.html',
    autor: 'Leticia Quintanal'
  },
  {
    fecha: '28/01/2024',
    fuente: 'El Comercio',
    titulo: 'Mónica Alario Gavilán',
    url: 'https://www.elcomercio.es/gijon/monica-alario-gavilan-20240128012811-nt.html',
    autor: 'Laura Castañón'
  },
  {
    fecha: '27/01/2024',
    fuente: 'Nueva España',
    titulo: 'Hay que atajar la pornografía de forma radical" Mónica Alario Gavilán, Investigadora, nombrada «Comadre de Oro»',
    url: 'https://www.lne.es/gijon/2024/01/27/hay-atajar-pornografia-forma-radical-97406247.html',
    autor: 'Nico Martínrz'
  },
  {
    fecha: '26/01/2024',
    fuente: 'Tribuna Feminista',
    titulo: 'El 1 de febrero se conmemora la histórica movilización del «tren de la libertad»',
    url: 'https://tribunafeminista.org/2024/01/1-de-febrero-se-conmemora-la-historica-movilizacion-del-tren-de-la-libertad/',
    autor: null
  },
  {
    fecha: '26/01/2024',
    fuente: 'Nueva España',
    titulo: 'Mónica Alario, nueva "Comadre de Oro", y Luis Rubiales, el "Felpeyu"',
    url: 'https://www.lne.es/gijon/2024/01/26/monica-alario-nueva-comadre-oro-97362861.html',
    autor: null
  },
  {
    fecha: '26/01/2024',
    fuente: 'Voz de Asturias',
    titulo: 'Les Comadres conceden sus originales premios para condenar el machismo: Pablo Motos gana el Babayu y Rubiales el Felpeyu',
    url: 'https://www.lavozdeasturias.es/noticia/asturias/2024/01/25/comadres-conceden-originales-premios-condenar-machismo-pablo-motos-gana-babayu-rubiales-felpeyu/00031706214699195564913.htm',
    autor: null
  },
  {
    fecha: '26/01/2024',
    fuente: 'El Comercio',
    titulo: 'La filósofa Mónica Alario, Comadre de Oro; Luis Rubiales, Felpeyu, y Pablo Motos, Babayu',
    url: 'https://www.elcomercio.es/gijon/luis-rubiales-pablo-motos-bertin-osborne-optan-20240119011730-nt.html',
    autor: null
  },
  {
    fecha: '23/01/2024',
    fuente: 'Vientos de cambio justo',
    titulo: 'EL TREN DE LA LIBERTAD',
    url: 'http://vientodejustocambio.blogspot.com/2024/01/el-tren-de-la-libertad.html',
    autor: 'José Antonio Bosch'
  },
  {
    fecha: '21/01/2024',
    fuente: 'La Razón',
    titulo: '¿Qué es el premio "Felpeyu" que puede ganar Luis Rubiales?',
    url: 'https://www.larazon.es/deportes/futbol/que-premio-felpeyu-que-puede-ganar-luis-rubiales_2024012165acef6ad8aa250001cfb69a.html',
    autor: 'E. Lunar'
  },
  {
    fecha: '20/01/2024',
    fuente: 'MIGIJÓN',
    titulo: '«Mientras los machistas sigan asesinando y violando, el "Tren de la Libertad" continuará circulando»',
    url: 'https://migijon.com/mientras-los-machistas-sigan-asesinando-y-violando-el-tren-de-la-libertad-continuara-circulando/',
    autor: 'Borja Pino'
  },
  {
    fecha: '19/01/2024',
    fuente: 'MIGIJÓN',
    titulo: 'Bertín Osborne, Pablo Motos y Rubiales, entre los candidatos a "Felpeyu" de les Comadres',
    url: 'https://migijon.com/bertin-osborne-pablo-motos-y-rubiales-entre-los-candidatos-a-felpeyu-de-les-comadres/',
    autor: null
  },
  {
    fecha: '19/01/2024',
    fuente: 'El Comercio',
    titulo: 'Luis Rubiales, Pablo Motos y Bertín Osborne optan al Felpeyu 2024',
    url: 'https://www.elcomercio.es/gijon/luis-rubiales-pablo-motos-bertin-osborne-optan-20240119011730-nt.html',
    autor: null
  },
  {
    fecha: '19/01/2024',
    fuente: 'Nueva España',
    titulo: 'Luis Rubiales y Pablo Motos, candidatos al premio "Felpeyu" de Les Comadres',
    url: 'https://www.lne.es/gijon/2024/01/19/luis-rubiales-pablo-motos-candidatos-97086839.html',
    autor: 'Sergio García'
  },
  {
    fecha: '13/01/2024',
    fuente: 'Nueva España',
    titulo: 'Coloquio de la Tertulia "Les Comadres"',
    url: 'https://www.lne.es/gijon/2024/01/13/coloquio-tertulia-les-comadres-96841837.html',
    autor: null
  },
  {
    fecha: '13/01/2024',
    fuente: 'El Comercio',
    titulo: 'Diez años de un hito feminista que sigue «vigilante frente a los retrocesos»',
    url: 'https://www.elcomercio.es/gijon/tren-libertad-gijon-diez-anos-hito-feminista-sigue-vigilante-frente-20240114215642-nt.html',
    autor: 'Eduardo Paneque'
  },
  {
    fecha: '12/01/2024',
    fuente: 'Ser Gijón',
    titulo: 'La "hazaña feminista" del Tren de la Libertad cumple una década',
    url: 'https://cadenaser.com/asturias/2024/01/09/la-hazana-feminista-del-tren-de-la-libertad-cumple-una-decada-ser-gijon/',
    autor: 'Begoña Natal'
  },
  {
    fecha: '12/12/2023',
    fuente: 'Youtube',
    titulo: 'NUEVOS SONIDOS PARA VIEJAS HISTORIAS',
    url: 'https://www.youtube.com/watch?v=8r6IjjBU1RA',
    autor: 'Laura Viñuela'
  },
  {
    fecha: '29/11/2023',
    fuente: 'Youtube',
    titulo: 'LA MÚSICA (LA DE AYER Y LA DE HOY) NO SOLO ES ALGO QUE SUENA',
    url: 'https://youtu.be/3jeOEePML9Q?si=CcswZb0y899iCw6s',
    autor: 'Pilar Rius'
  },
  {
    fecha: '12/10/2023',
      fuente: 'El Comercio',
      titulo: 'Les Comadres elogian la elección de los Nobel de la Paz y Economía',
      url: 'https://www.elcomercio.es/gijon/comadres-elogian-eleccion-nobel-paz-economia-20231012002622-nt.html',
      autor: null
    },
    {
      fecha: '20/06/2023',
      fuente: 'RTPA',
      titulo: 'Entrevista Begoña Piñero con respecto al acuerdo de Moriyón y Foro',
      url: 'https://www.rtpa.es/audio:20hoy%201%C2%AA%20edici%C3%B3n_1687244820.html',
      autor: null
    },
    {
      fecha: '18/06/2023',
      fuente: 'Youtube',
      titulo: 'El género daña" Sheila Jeffreys. Conversación con Amelia Valcárcel',
      url: 'https://www.youtube.com/watch?v=eFted3YlP8U',
      autor: null
    },
    {
      fecha: '16/06/2023',
      fuente: 'La Nueva España',
      titulo: 'Placas de homenaje para las «pioneras» Rosario de Acuña y Concepción Arenal en el Botánico',
      url: 'https://www.lne.es/gijon/2023/06/16/placas-homenaje-pioneras-rosario-acuna-88761383.html',
      autor: 'Sergio García_ Marcos León'
    },
    {
      fecha: '06/05/2023',
      fuente: 'Youtube',
      titulo: 'Ofrenda floral Rosario Acuña',
      url: 'https://www.youtube.com/watch?v=PvyrrkA11KM',
      autor: 'Intervención Ana Rodríguez'
    },
    {
      fecha: '06/03/2023',
      fuente: 'Youtube',
      titulo: 'GABRIELA MISTRAL, poesía, feminismo y justicia social',
      url: 'https://mail.google.com/mail/u/0/#inbox/FMfcgzGsmhdTFqkpMPggDpTCjhgZZZTj?projector=1',
      autor: 'Proyecto de Antolina de la Fuente'
    },
    {
      fecha: '28/01/2023',
      fuente: 'El Comercio',
      titulo: 'Les Comadres proponen al arzobispo como Felpeyu 2023',
      url: 'https://www.elcomercio.es/gijon/comadres-proponen-arzobispo-20230128002925-ntvo.html',
      autor: 'E.C'
    },
    {
      fecha: '27/09/2022',
      fuente: 'Youtube',
      titulo: 'Humor para reir y pensar el feminismo',
      url: 'https://www.youtube.com/watch?v=0Zs4ivkZG58',
      autor: 'Lula Gomez'
    },
    {
      fecha: '25/10/2022',
      fuente: 'El Comercio',
      titulo: 'Feminismo desde el humor',
      url: 'https://www.elcomercio.es/gijon/feminismo-humor-20221025002544-ntvo.html',
      autor: null
    },
    {
      fecha: '18/05/2022',
      fuente: 'El Comercio',
      titulo: 'Rosario Carracedo: «Queremos la abolición del sistema prostitucional»',
      url: 'https://www.elcomercio.es/gijon/rosario-carracedo-queremos-20220517140520-video.html',
      autor: 'Diego Abejón'
    },
    {
      fecha: '18/05/2022',
      fuente: 'La Nueva España',
      titulo: 'Jornada sobre la abolición de la prostitución',
      url: 'https://www.lne.es/gijon/2022/05/18/jornada-abolicion-prostitucion-66218913.html',
      autor: 'Ángel Glz'
    },
    {
      fecha: '17/05/2022',
      fuente: 'El Comercio',
      titulo: 'Begoña Piñero : «Era hora de que eso se contemplara»',
      url: 'https://www.elcomercio.es/gijon/begona-pinero-hora-20220517203506-video.html',
      autor: 'Diego Abejón'
    },
    {
      fecha: '31/10/2021',
      fuente: 'La sexta',
      titulo: 'El tren de la libertad de 2014 - Las feministas asturianas que pusieron en jaque a Gallardón: así movilizaron a España contra la ley del aborto del PP',
      url: 'https://www.lasexta.com/programas/donde-estabas-entonces/feministas-asturianas-que-pusieron-jaque-gallardon-asi-movilizaron-espana-ley-aborto_20211028617ae24bc1b52e0001325b33.html',
      autor: null
    },
    {
      fecha: '05/10/2021',
      fuente: 'La Voz de Asturias',
      titulo: 'Mabel Lozano: «Todos los niños llevan un pequeño cine porno en su bolsillo»',
      url: 'https://www.lavozdeasturias.es/noticia/asturias/2021/10/05/mabel-lozano-ninos-llevan-pequeno-cine-porno-bolsillo/00031633420954742642936.htm',
      autor: 'Marcos Gutiérrez'
    },
    {
      fecha: '05/10/2021',
      fuente: 'El Comercio',
      titulo: '«La prostitución ha migrado a fronteras digitales demasiado accesibles y salvajes»',
      url: 'https://www.elcomercio.es/gijon/prostitucion-migrado-fronteras-20211005000624-ntvo.html',
      autor: 'Guillermo Maese'
    },
    {
      fecha: '17/09/2021',
      fuente: 'La Nueva España',
      titulo: 'Tasia Aranguez, con "Les Comadres"',
      url: 'https://www.lne.es/gijon/2021/09/17/teresa-aranguez-les-comadres-57379744.html',
      autor: null
    },
    {
      fecha: '12/02/2021',
      fuente: 'La Nueva España',
      titulo: 'Un ciento de comadres online',
      url: 'https://www.lne.es/gijon/2021/02/12/ciento-comadres-online-34535257.html',
      autor: 'Lujan Palacios'
    },
    {
      fecha: '11/02/2021',
      fuente: 'El Comercio',
      titulo: '«El premio "Comadre de Oro" es el más especial de todos»',
      url: 'https://www.elcomercio.es/gijon/premio-comadre-especial-20210211000945-ntvo.html',
      autor: 'Eugenia García'
    },
    {
      fecha: '10/02/2021',
      fuente: 'El Comercio',
      titulo: 'Una dosis de comadres contra el virus',
      url: 'juevesdecomadres/otros/Jueves de Comadres 2021/comercio.jpg',
      autor: 'Eduardo García'
    },
    {
      fecha: '10/02/2021',
      fuente: 'Nueva España',
      titulo: 'La tertulia feminista entrega mañana sus premios con una reunión virtual de 100 mujeres',
      url: 'juevesdecomadres/otros/Jueves de Comadres 2021/nueva españa.jpg',
      autor: 'Pablo Antuña'
    },{
      fecha: '05/02/2021',
      fuente: 'La Voz de Asturias',
      titulo: 'Les Comadres «premian» las «babayaes sexistas y misóginas» de Canteli',
      url: 'https://www.lavozdeasturias.es/noticia/gijon/2021/02/05/comadres-premian-babayaes-sexistas-misoginas-canteli/00031612539600397772600.htm',
      autor: null
    },
    {
      fecha: '05/02/2021',
      fuente: 'Europapress',
      titulo: "'Les Comadres' elige a Mabel Lozano 'Comadre de Oro' y otorga el 'Felpeyu' al 'Trifachito' y a Canteli el 'Babayu'",
      url: 'https://www.europapress.es/asturias/noticia-les-comadres-elige-mabel-lozano-comadre-oro-otorga-felpeyu-trifachito-canteli-babayu-20210205155724.html',
      autor: null
    },
    {
      fecha: '05/02/2021',
      fuente: 'La Vanguardia',
      titulo: "Les Comadres' elige a Mabel Lozano 'Comadre de Oro' y otorga el 'Felpeyu' al 'Trifachito' y a Canteli el 'Babayu'",
      url: 'https://www.lavanguardia.com/local/asturias/20210205/6226210/les-comadres-elige-mabel-lozano-comadre-oro-otorga-felpeyu-trifachito-canteli-babayu.amp.html',
      autor: null
    },
    {
      fecha: '05/02/2021',
      fuente: 'Cadena Ser',
      titulo: 'MABEL LOZANO, nueva Comadre de Oro',
      url: 'https://play.cadenaser.com/audio/1612531861987/?ssm=whatsapp',
      autor: 'Alicia Álvarez'
    },
    {
      fecha: '04/02/2021',
      fuente: 'La Nueva España',
      titulo: 'El alcalde de Oviedo y el "trifachito", premios "Babayu" y "Felpeyu" de "Les Comadres"',
      url: 'https://www.lne.es/gijon/2021/02/04/alcalde-oviedo-trifachito-premios-babayu-34081202.html',
      autor: null
    },
    {
      fecha: '29/01/2021',
      fuente: 'El Comercio',
      titulo: 'Miguel Bosé, entre los candidatos al "Felpeyu" de 2021',
      url: 'https://www.elcomercio.es/gijon/comadres-oro-felpeyu-candidatos-2021-20210129105253-nt.html',
      autor: 'Susana Tejedor'
    },
    {
      fecha: '29/01/2021',
      fuente: 'La Nueva España',
      titulo: 'El cantante Miguel Bosé, el alcalde de Madrid, Martínez Almeida, y Monedero, entre los candidatos al "Felpeyu" de 2021',
      url: 'https://www.lne.es/gijon/2021/01/29/cantante-miguel-bose-alcalde-madrid-32258277.html',
      autor: null
    },
    {
      fecha: '26/01/2021',
      fuente: 'Cadena Ser',
      titulo: 'Intervención Begoña Piñero en hoy por hoy Gijón, hablando de Jueves de Comadres',
      url: 'https://play.cadenaser.com/audio/1611668828732/?ssm=whatsapp',
      autor: 'Alicia Álvarez'
    },
    {
      fecha: '27/10/2020',
      fuente: 'Youtube',
      titulo: '"La necesidad de un feminismo post-género"',
      url: 'https://www.youtube.com/watch?v=cvJoP6Vqrp0',
      autor: 'Rosa María Rodriguez Magda'
    },
    {
      fecha: '14/12/2020',
      fuente: 'Youtube',
      titulo: 'Pantalla para un debate: Desafios del ahora',
      url: 'https://www.youtube.com/watch?v=ZDRo9upzDD0',
      autor: 'Amelia Valcárcel'
    },
    {
      fecha: '04/12/2020',
      fuente: 'Youtube',
      titulo: 'Pantalla para un debate: Islám y mjer. La encrucijada',
      url: 'https://www.youtube.com/watch?v=Y1PDLGAkwS8&feature=youtu.be',
      autor: 'Najat El Hachmi'
    },
    {
      fecha: '23/10/2020',
      fuente: 'Youtube',
      titulo: 'Pantalla para un debate: La necesidad de un feminismo postgénero',
      url: 'https://www.youtube.com/watch?v=cvJoP6Vqrp0&feature=youtu.be',
      autor: 'Rosa María Rodríguez Magda'
    },
    {
      fecha: '01/07/2020',
      fuente: 'Cadena Ser',
      titulo: 'Begoña Piñero: "Estamos en un intento de diluir lo que es el feminismo"',
      url: 'https://play.cadenaser.com/audio/1593620732_078548/',
      autor: null
    },
    {
      fecha: '01/02/2020',
      fuente: 'El Comercio',
      titulo: '«Muchas mujeres tomaron conciencia del feminismo con el "tren de la libertad"»',
      url: 'https://www.elcomercio.es/gijon/mujeres-tomaron-conciencia-20200201011531-ntvo.html',
      autor: 'Marla Nieto'
    },
    {
      fecha: '31/01/2020',
      fuente: 'Onda Cero',
      titulo: 'Entrevista Tren de la Libertad',
      url: 'https://www.ondacero.es/emisoras/asturias/gijon/mas-de-uno-gijon-31012020-con-guillermo-figueroa_202001315e3426690cf2e7657591ed97.html',
      autor: 'Guillermo Figuero'
    },
    {
      fecha: '31/01/2020',
      fuente: 'RTPA',
      titulo: 'Presentación del llibru "El Tren de la libertad" na Escuela de Comerciu de Xixón',
      url: 'https://www.rtpa.es/noticias-sociedad:Presentacion-del-llibru-%27El-Tren-de-la-libertad%27-na-Escuela-de-Comerciu-de-Xixon_111580466286.html',
      autor: null
    },
    {
      fecha: '30/01/2019',
      fuente: 'Cadena Ser',
      titulo: 'Un libro recoge la historia del Tren de la Libertad',
      url: 'https://cadenaser.com/emisora/2020/01/30/ser_gijon/1580389901_279188.html',
      autor: 'Alicia Álvarez'
    },
    {
      fecha: '05/12/2019',
      fuente: 'La Nueva España',
      titulo: 'Multitudinario homenaje a Rosario Fernández Hevia, la "jueza valiente"',
      url: 'https://www.lne.es/gijon/2019/12/05/multitudinario-homenaje-rosario-fernandez-hevia/2567587.html',
      autor: 'J.M.C.'
    },
    {
      fecha: '05/12/2019',
      fuente: 'La Voz de Asturias',
      titulo: '«Charo siempre fue la abogada de las causas perdidas y una rebelde»',
      url: 'https://www.lavozdeasturias.es/noticia/gijon/2019/12/04/charo-siempre-abogada-causas-perdidas-rebelde/00031575490701285657732.htm',
      autor: 'Marcos Gutiérrez'
    },
    {
      fecha: '22/11/2019',
      fuente: 'Youtube',
      titulo: 'Primer premio concedido por la Concejalía de Igualdad del Ayuntamiento de Móstoles a la Tertulia',
      url: 'https://www.youtube.com/watch?v=yaPbqeVXNLg&feature=emb_title',
      autor: null
    },
    {
      fecha: '24/05/2019',
      fuente: 'El Comercio',
      titulo: 'In Memorian a Charo Fernández Hevia',
      url: 'http://lector.kioskoymas.com/epaper/iphone/homepage.aspx?isBookmark=true&locationHash=#_articlef6d8cd34-76d2-437e-9ad4-100f9a5b6b8a/waarticlef6d8cd34-76d2-437e-9ad4-100f9a5b6b8a/RN10FKIDVSJ6#_articlef6d8cd34-76d2-437e-9ad4-100f9a5b6b8a',
      autor: 'Begoña Piñero'
    },
    {
      fecha: '11/05/2019',
      fuente: 'Youtube',
      titulo: 'Homenaje Rosario Acuña',
      url: 'https://www.youtube.com/watch?v=kNorq9XajHs',
      autor: null
    },
    {
      fecha: '07/05/2019',
      fuente: 'RTPA',
      titulo: 'Vidas públicas, vidas privadas con Amelia Valcárcel',
      url: 'https://www.rtpa.es/video:vidas publicas, vidas privadas_551557272700.html',
      autor: null
    },
    {
      fecha: '08/03/2019',
      fuente: 'Youtube',
      titulo: 'Señores al borde de un ataque de nervios',
      url: 'https://www.youtube.com/watch?v=-t_Mapw66i4',
      autor: null
    },
    {
      fecha: '14/12/2018',
      fuente: 'La Nueva España',
      titulo: 'Existen microviolencias sanitarias',
      url: 'https://www.lne.es/gijon/2018/12/14/existen-microviolencias-sanitarias-necesario/2396482.html',
      autor: 'Carmen Valls'
    },
    {
      fecha: '24/10/2018',
      fuente: 'La Nueva España',
      titulo: 'Una feminista que fue Comadre de Oro en Gijón',
      url: 'https://www.lne.es/gijon/2018/10/24/feminista-comadre-oro/2369033.html',
      autor: null
    },
    {
      fecha: '24/10/2018',
      fuente: 'El Comercio',
      titulo: 'La Tertulia Feminista Les Comadres de Gijón pierde a una de las suyas',
      url: 'https://www.elcomercio.es/politica/carmen-alborch-comadre-oro-gijon-2000-20181024202433-nt.html',
      autor: null
    },
    {
      fecha: '05/06/2018',
      fuente: 'El Comercio',
      titulo: '«España es el país que más gasta en prostitución de la UE y Asturias destaca»',
      url: 'https://www.elcomercio.es/gijon/espana-pais-gasta-20180605001705-ntvo.html',
      autor: 'Rosa Cobo'
    },
    {
      fecha: '15/06/2017',
      fuente: 'La Nueva España',
      titulo: 'Asturias exige en la calle un feminismo real',
      url: 'https://www.lavozdeasturias.es/noticia/asturias/2018/05/16/asturiasexige-calle-feminismo-real/00031526485972464568557.htm',
      autor: null
    },
    {
      fecha: '28/05/2018',
      fuente: 'Cadena Ser',
      titulo: 'El supremo aplica por primera vez la perspectiva de género en un caso de violencia machista',
      url: 'http://cadenaser.com/ser/2018/05/28/tribunales/1527520021_450598.html',
      autor: null
    },
    {
      fecha: '22/05/2018',
      fuente: 'Público',
      titulo: 'La diputada que alteró los planes de gobierno',
      url: 'https://www.publico.es/politica/diputada-altero-planes-gobierno.html',
      autor: null
    },
    {
      fecha: '16/05/2018',
      fuente: 'RTPA',
      titulo: 'Cientos de mujeres reclaman financiación para la lucha contra la violencia machista',
      url: 'https://www.rtpa.es/asturias:--Cientos-de-mujeres-reclaman-financiacion-para-la-lucha-contra-la-violencia-machista_111526471809.html',
      autor: null
    },
    {
      fecha: '11/05/2018',
      fuente: 'La Voz de Asturias',
      titulo: 'Asturias incluye una asignatura de Igualdad de Género en los institutos',
      url: 'https://www.lavozdeasturias.es/noticia/asturias/2018/05/10/asturias-incluye-asignatura-igualdad-genero-institutos/00031525969810778719484.htm',
      autor: null
    },
    {
      fecha: '04/05/2018',
      fuente: 'Youtube',
      titulo: 'Homenaje Rosario Acuña',
      url: 'https://drive.google.com/file/d/1j8kP-ldTpP-RQGhr2OTIJvNMSRQYuB9N/view?usp=drivesdk',
      autor: null
    },
    {
      fecha: '26/04/2018',
      fuente: 'Tribuna Feminista',
      titulo: 'El Tribunal Constitucional portugués anula la ley de vientres de alquiler',
      url: 'https://tribunafeminista.elplural.com/2018/04/el-tribunal-constitucional-portugues-anula-la-ley-de-vientres-de-alquiler/',
      autor: null
    },
    {
      fecha: '03/05/2018',
      fuente: 'El País',
      titulo: 'El nuevo sujeto emancipador',
      url: 'https://elpais.com/elpais/2018/05/02/opinion/1525271224_542845.html',
      autor: null
    },
    {
      fecha: '08/03/2018',
      fuente: 'Público',
      titulo: 'El Tren de la Libertad: el movimiento que sostuvo el derecho a decidir',
      url: 'http://www.publico.es/mujer/aborto-tren-libertad-movimiento-sostuvo-derecho-decidir',
      autor: null
    },
    {
      fecha: '07/03/2018',
      fuente: 'Público',
      titulo: 'El tren de la libertad: el movimiento que sostuvo el derecho a decidir',
      url: 'https://www.publico.es/mujer/aborto-tren-libertad-movimiento-sostuvo-derecho-decidir',
      autor: 'Beatriz Asuar Gallego'
    },
    {
      fecha: '07/03/2018',
      fuente: 'Público',
      titulo: '¡Hasta aquí hemos llegado ! ni podemos ni queremos esperar más',
      url: 'https://www.publico.es/mujer/hasta-aqui-hemos-llegado',
      autor: 'Ana de Miguel'
    },
    {
      fecha: '07/03/2018',
      fuente: 'Público',
      titulo: 'Un movimiento imparable, porque estamos hastas de esperar',
      url: 'http://claridad.wp-ugt.org/movimiento-imparable-hartas-esperar/',
      autor: 'Montserrat Boix'
    },  {
      fecha: '28/06/2017',
      fuente: 'La Nueva España',
      titulo: 'Simone de Beauvoir guía a Esquembre al recoger el premio "Comadre de Oro"',
      url: 'http://www.lne.es/gijon/2017/06/15/simone-beauvoir-guia-esquembre-recoger/2121111.html',
      autor: null
    },
    {
      fecha: '15/06/2017',
      fuente: 'La Nueva España',
      titulo: 'Simone de Beauvoir guía a Esquembre al recoger el premio "Comadre de Oro"',
      url: 'http://www.lne.es/gijon/2017/06/15/simone-beauvoir-guia-esquembre-recoger/2121111.html',
      autor: null
    },
    {
      fecha: '09/01/2017',
      fuente: 'IVOOX',
      titulo: 'Intervención de Begoña Piñero en la "Mesa de Igualdad" en RPA noticias',
      url: 'http://www.ivoox.com/mesa-igualdad-asturiashoy1-arpanoticias-audios-mp3_rf_15997438_1.html',
      autor: null
    },
    {
      fecha: '21/12/2016',
      fuente: 'IVOOX',
      titulo: 'Begoña Piñero hablando sobre la Tertulia en Radio Kras',
      url: 'http://www.ivoox.com/autofoto-62-tertulia-feminista-les-comadres-audios-mp3_rf_15269795_1.html',
      autor: null
    },
    {
      fecha: '13/07/2016',
      fuente: 'RTPA',
      titulo: 'Más de un millar de mujeres sufren una violación al año en España',
      url: 'https://www.rtpa.es/noticias-nacional:Mas-de-un-millar-de-mujeres-sufren-una-violacion-al-año-en-España-_111468399115.html',
      autor: 'Begoña Piñero'
    },
    {
      fecha: '08/03/2016',
      fuente: 'La Nueva España',
      titulo: 'Gijón entra en la historia del feminismo con la inauguración de los jardines del Tren de la LIbertad',
      url: 'http://www.lne.es/gijon/2016/03/08/callejero-entra-historia-feminismo-inauguracion/1894138.html',
      autor: null
    },
    {
      fecha: '08/03/2016',
      fuente: 'La Nueva España',
      titulo: 'Inauguración de los Jardines del Tren de la Libertad',
      url: 'http://www.lne.es/multimedia/fotos/gijon/2016-03-08-54421-inauguracion-jardines-tren-libertad.html',
      autor: null
    },
    {
      fecha: '08/03/2016',
      fuente: 'El Comercio',
      titulo: 'El Tren de la Libertad ya da nombre a un parque en Gijón',
      url: 'http://www.elcomercio.es/fotos/gijon/201603/08/tren-libertad-nombre-parque-30124037576566-mm.html?edition=',
      autor: null
    },
    {
      fecha: '08/03/2016',
      fuente: 'El Comercio',
      titulo: 'El parque del solaron ya tiene nombre: "Tren de la libertad"',
      url: 'http://www.elcomercio.es/videos/gijon/201603/08/parque-solaron-tiene-nombre-4791877876001-mm.html',
      autor: null
    },
    {
      fecha: '09/03/2016',
      fuente: 'Asturias 24',
      titulo: 'El parque del Tren de la Libertad: zona malva en zona verde',
      url: 'http://www.asturias24.es/secciones/gijon/noticias/el-parque-del-tren-de-la-libertad-zona-malva-en-zona-verde/1457467411',
      autor: null
    },
    {
      fecha: '09/03/2016',
      fuente: 'La Nueva España',
      titulo: 'El Tren de la Libertad llega a su destino',
      url: 'http://www.lne.es/gijon/2016/03/09/tren-libertad-llega-destino/1894376.html',
      autor: null
    },
    {
      fecha: '10/03/2016',
      fuente: 'El digital de Asturias',
      titulo: 'Inauguración de los Jardines del Tren de la Libertad',
      url: 'https://www.eldigitaldeasturias.com/noticias/inauguracion-de-los-jardines-tren-de-la-libertad/',
      autor: null
    },
    {
      fecha: '29/01/2016',
      fuente: 'El Comercio',
      titulo: 'Comadres premia a la filósofa Ana de Miguel y nombra Felpeyu al arzobispo de Granada',
      url: 'http://www.elcomercio.es/gijon/201601/29/comadres-premia-filosofa-miguel-20160129001514-v.html',
      autor: null
    },
    {
      fecha: '29/01/2016',
      fuente: 'La Nueva España',
      titulo: 'Las filósofas Ana de Miguel y Alicia Miyares serán homenajeadas por "Les Comadres"',
      url: 'http://www.lne.es/gijon/2016/01/29/filosofas-ana-miguel-alicia-miyares/1875200.html',
      autor: null
    },
    {
      fecha: '22/01/2016',
      fuente: 'El Comercio',
      titulo: 'Candidaturas Premios 2016',
      url: 'http://m.elcomercio.es/gijon/201601/22/treinta-anos-comadre-felpeyu-20160122001039-v.html',
      autor: null
    },
    {
      fecha: '22/01/2016',
      fuente: 'La Nueva España',
      titulo: 'Candidaturas Premios 2016',
      url: 'http://www.lne.es/gijon/2016/01/22/maria-emilia-casas-almudena-grandes/1871799.html',
      autor: null
    },
    {
      fecha: '17/01/2016',
      fuente: 'La Nueva España',
      titulo: 'Las maquinistas del Tren de la Libertad',
      url: 'http://www.lne.es/gijon/2016/01/17/maquinistas-libertad/1869488.html',
      autor: null
    },
    {
      fecha: '15/01/2016',
      fuente: 'El Comercio',
      titulo: 'Celebración del nombre "Jardines del Tren de la Libertad"',
      url: 'http://www.elcomercio.es/gijon/201601/15/mujeres-tren-libertad-celebran-20160115002358-v.html',
      autor: null
    },
    {
      fecha: '14/01/2016',
      fuente: 'El Comercio',
      titulo: 'El Solarón se llamará "Tren de la libertad"',
      url: 'http://www.elcomercio.es/gijon/201601/14/solaron-llamara-tren-libertad-20160113225020.html',
      autor: null
    },
    {
      fecha: '11/01/2016',
      fuente: 'El Comercio',
      titulo: 'Begoña Piñero en relación al nombre "Jardines del tren de la libertad"',
      url: 'http://www.elcomercio.es/gijon/201601/11/tren-libertad-comida-entre-20160111001356-v.html',
      autor: null
    },
    {
      fecha: '11/01/2014',
      fuente: 'IVOOX',
      titulo: 'Cuña promocional del Tren de la Libertad',
      url: 'http://www.ivoox.com/subete-al-tren-libertad-audios-mp3_rf_2704811_1.html',
      autor: null
    },
    {
      fecha: '10/01/2014',
      fuente: 'El Comercio',
      titulo: "El 'Tren de la Libertad' viajará hacia Madrid para pedir que se mantenga la actual ley del aborto",
      url: 'http://www.elcomercio.es/20140110/asturias/organizaciones-mujeres-asturianas-suben-201401101813.html',
      autor: null
    },
    {
      fecha: '08/01/2014',
      fuente: 'IVOOX',
      titulo: 'Entrevista con Begoña Piñero',
      url: 'http://www.ivoox.com/tren-libertad-audios-mp3_rf_2701033_1.html',
      autor: null
    },
    {
      fecha: '04/01/2014',
      fuente: 'La Nueva España',
      titulo: 'Optimismo por resistencia. La reforma de la ley del aborto, más sufrimiento para una sociedad extenuada',
      url: 'http://www.lne.es/opinion/2014/01/03/optimismo-resistencia/1522322.html',
      autor: null
    },
    {
      fecha: '30/12/2013',
      fuente: 'La Nueva España',
      titulo: 'Mujeres del valle se movilizarán en Madrid contra la reforma de la ley del aborto',
      url: 'http://www.lne.es/nalon/2013/12/30/mujeres-valle-movilizaran-madrid-reforma/1521073.html',
      autor: null
    },
    {
      fecha: '28/11/2010',
      fuente: 'La Nueva España',
      titulo: 'Los discapacitados dan la vuelta a la tortilla',
      url: '/pdf/tortilla.pdf',
      autor: null
    },
    {
      fecha: '10/11/2010',
      fuente: 'Europapress',
      titulo: 'El Consejo Asturiano de la Mujer rechaza la supresión, por motivos "ideológicos", del Ministerio de Igualdad',
      url: '/pdf/SupresionIgualdad_2.pdf',
      autor: null
    },
    {
      fecha: '05/11/2010',
      fuente: 'Europapress',
      titulo: 'El Consejo de Asociaciones de Gijón rechaza, con la abstención del PP, la supresión del Ministerio de Igualdad',
      url: '/pdf/SupresionIgualdad.pdf',
      autor: null
    },
    {
      fecha: '31/10/2010',
      fuente: 'El Comercio',
      titulo: 'Flores malvas para todos (y todas). Perfil de Begoña Piñero',
      url: '/pdf/PerfilBego.pdf',
      autor: null
    },
    {
      fecha: '27/09/2010',
      fuente: 'pdf',
      titulo: 'La mitad de los emigrantes españoles se fueron sin contrato de trabajo',
      url: '/pdf/trenmemoria.pdf',
      autor: null
    },
    {
      fecha: '28/08/2010',
      fuente: 'La Nueva España',
      titulo: 'Si hay trata, no hay trato',
      url: '/pdf/palumbo.pdf',
      autor: null
    },
    {
      fecha: '24/04/2010',
      fuente: 'La Nueva España',
      titulo: 'Un libro, mujeres y el «hiyab»',
      url: '/pdf/EnigmaAna.pdf',
      autor: null
    },
    {
      fecha: '26/02/2010',
      fuente: 'Europa Press',
      titulo: 'El Ayuntamiento y el Consejo de Asociaciones de mujeres aplauden la aprobación de la Ley del Aborto',
      url: '/pdf/AbortoFebrero2010.pdf',
      autor: null
    },
    {
      fecha: '17/12/2004',
      fuente: 'La Nueva España',
      titulo: 'Primera lección para las mujeres',
      url: '/pdf/menopausia.pdf',
      autor: null
    },
    {
      fecha: '28/11/2004',
      fuente: 'El Comercio',
      titulo: 'Entrevista con Begoña Piñero, presidenta de les Comadres',
      url: '/pdf/entrevista.pdf',
      autor: null
    },
    {
      fecha: '05/11/2004',
      fuente: 'La Nueva España',
      titulo: 'Morant: Lo privado sigue siendo asunto político, en especial para las mujeres',
      url: '/pdf/feminismos.pdf',
      autor: null
    },
    {
      fecha: '23/04/2004',
      fuente: 'El Comercio',
      titulo: 'Asturianas que hicieron historia',
      url: '/pdf/transicion.pdf',
      autor: null
    }
  ];

const NoticiasPropias = () => {
  const [allData, setAllData] = useState([...articulos]);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = process.env.REACT_APP_API_TOKEN;

    axios.get(`${apiUrl}/api/hemerotecas`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(respuesta => {
        if (respuesta.data.data) {
          const datosFiltrados = respuesta.data.data.filter(item => 
            item.attributes.title !== 'Insertamos en esta sección algunos de los artículos que distintos medios de comunicación han ido editando relacionados con nuestras actividades'
          );
          
          const datosFormateados = datosFiltrados.map(item => ({
            fecha: item.attributes.fecha ? 
              new Date(item.attributes.fecha).toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
              }) : '',
            titulo: item.attributes.titulo,
            url: item.attributes.url,
            fuente: item.attributes.fuente || '',
            autor: item.attributes.autor || null
          }));

          setAllData([...datosFormateados, ...articulos]);
        }
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
      });
  }, []);

  return (
    <HemerotecaContainer>
      <Header>
        <img src="/prensa.JPG" alt="Prensa icon" />
        <h2>Noticias Propias</h2>
      </Header>
      
      {allData.map((item, index) => (
        <ArticuloContainer key={index}>
          {fuenteLogos[item.fuente] ? (
            <FuenteLogo src={fuenteLogos[item.fuente]} alt={item.fuente} />
          ) : (
            <DefaultBullet />
          )}
          <ArticuloInfo>
            <Fecha>{item.fecha}</Fecha>
            <ArticuloLink href={item.url} target="_blank" rel="noopener noreferrer">
              {item.titulo}
            </ArticuloLink>
            {item.autor && <Autor>{item.autor}</Autor>}
          </ArticuloInfo>
        </ArticuloContainer>
      ))}
    </HemerotecaContainer>
  );
}

export default NoticiasPropias;
