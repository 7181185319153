import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components'; 

const FinesContainer = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Fines = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = process.env.REACT_APP_API_TOKEN;

    axios.get(`${apiUrl}/api/fines`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        console.log('API Response:', response.data);
        if (response.data.data && response.data.data.length > 0) {
          setData(response.data.data[0].attributes);
        } else {
          setError('No se encontraron datos de Fines');
        }
      })
      .catch(error => {
        console.error('Hubo un error al obtener los datos de Fines!', error);
        setError('Error al cargar los datos');
      });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data) {
    return <div>Cargando...</div>;
  }

  const renderChildren = (children) => {
    return children.map((child, index) => {
      if (child.type === 'text') {
        return child.text;
      }
      return null;
    });
  };

  const renderContentBlock = (block, index) => {
    switch (block.type) {
      case 'paragraph':
        return <p key={index}>{renderChildren(block.children)}</p>;
      default:
        return null;
    }
  };

  return (
    <FinesContainer>
      <h1>{data.Titulo}</h1>
      <div>
        {data.Contenido.map((block, index) => (
          renderContentBlock(block, index)
        ))}
      </div>
    </FinesContainer>
  );
}

export default Fines;
