import React from 'react';
import styled from 'styled-components';
import { parsePublicationsHTML, processHTMLContent } from './utils/archivosParser';
const ArchivosContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h1`
  color: #8a2be2;
  text-align: center;
  margin-bottom: 2rem;
`;

const ArchivosGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const ArchivoCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ArchivoTitle = styled.h3`
  color: #4a5d4e;
  margin-bottom: 1rem;
`;

const ArchivoDescription = styled.p`
  color: #666;
  line-height: 1.6;
`;

const DownloadButton = styled.a`
  display: inline-block;
  background-color: #cc99ff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  text-decoration: none;
  margin-top: 1rem;
  
  &:hover {
    background-color: #8a2be2;
  }
`;

const ContentViewer = styled.div`
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
  max-width: 800px;

  img {
    max-width: 100%;
    height: auto;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #4a5d4e;
    margin-bottom: 1rem;
  }

  p {
    line-height: 1.6;
    color: #666;
    margin-bottom: 1rem;
  }
`;

const HTMLContent = styled.div`
  font-family: Arial, sans-serif;
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
  }
  
  td, th {
    padding: 0.5rem;
    border: 1px solid #ddd;
  }
  
  a {
    color: #8a2be2;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
`

const Archivos = () => {
  const [archivos, setArchivos] = React.useState([]);
  const [selectedContent, setSelectedContent] = React.useState(null);

  React.useEffect(() => {
    const cargarArchivos = async () => {
      try {
        const response = await fetch('enlaweb.htm');
        const htmlContent = await response.text();
        const archivosData = await parsePublicationsHTML(htmlContent);
        const archivosArray = archivosData.data || [];
        setArchivos(archivosArray);
      } catch (error) {
        console.error('Error al cargar los archivos:', error);
      }
    };

    cargarArchivos();
  }, []);

    const archivosToRender = Array.isArray(archivos) ? archivos : [];

    const handleArchivoClick = async (url) => {
      // Verificar si la URL es externa
      const isExternal = url.startsWith('http://') || url.startsWith('https://');

      if (isExternal) {
        // Abrir URLs externas en una nueva pestaña
        window.open(url, '_blank', 'noopener,noreferrer');
      } else if (url.toLowerCase().endsWith('.pdf')) {
        // Abrir PDFs en una nueva pestaña
        window.open(url, '_blank', 'noopener,noreferrer');
      } else if (url.endsWith('.htm') || url.endsWith('.html')) {
        // Procesar contenido HTML interno
        const content = await processHTMLContent(url);
        if (content) {
          setSelectedContent(content);
        }
      } else {
        // Abrir otros tipos de archivos en una nueva pestaña
        window.open(url, '_blank', 'noopener,noreferrer');
      }
    };

    const ContentViewer = ({ content }) => {
      if (!content) return null;

      if (content.isPDF) {
        return null; // Los PDFs se abren en nueva pestaña
      }

      return (
        <div className="content-viewer">
          <style>{content.styles}</style>
          <h2>{content.title}</h2>
          {content.mainContent && (
            <div 
              className="main-content"
              dangerouslySetInnerHTML={{ __html: content.mainContent }} 
            />
          )}
          {content.tableContent && (
            <div 
              className="table-content"
              dangerouslySetInnerHTML={{ __html: content.tableContent }} 
            />
          )}
        </div>
      );
    };

    return (
      <ArchivosContainer>
        <Title>Archivos Históricos</Title>
        {selectedContent ? (
          <ContentViewer content={selectedContent} />
        ) : (
          <ArchivosGrid>
            {archivosToRender.map((archivo, index) => (
              <ArchivoCard key={`archivo-${index}`}>
                <ArchivoTitle>{archivo.attributes.Title}</ArchivoTitle>
                <ArchivoDescription>
                  {archivo.attributes.Description}
                </ArchivoDescription>
                <DownloadButton 
                  onClick={() => handleArchivoClick(archivo.attributes.URL)}
                >
                  Ver más detalles
                </DownloadButton>
              </ArchivoCard>
            ))}
          </ArchivosGrid>
        )}
      </ArchivosContainer>
    );
};

export default Archivos; 