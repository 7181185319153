export function parseCartelesHTML(htmlContent) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const carteles = [];
  
  // Encontrar todas las celdas que contienen carteles
  const cartelCells = doc.querySelectorAll('td[align="center"][valign="top"]');
  
  cartelCells.forEach((cell, index) => {
    // Extraer año del enlace
    const yearLink = cell.querySelector('a');
    if (!yearLink) return;
    
    const yearText = yearLink.textContent;
    const yearMatch = yearText.match(/(\d{4})/);
    if (!yearMatch) return;
    
    // Extraer diseñador
    const designerSpan = cell.querySelector('.Estilo14');
    if (!designerSpan) return;
    const designerText = designerSpan.textContent.replace('Diseño:', '').trim();
    
    // Extraer URL de la imagen
    const imgLink = cell.querySelector('img');
    if (!imgLink) return;
    let imgUrl = imgLink.getAttribute('src');
    
    // Asegurarse de que la URL comience con /juevesdecomadres
    if (!imgUrl.startsWith('/')) {
      imgUrl = '/' + imgUrl;
    }
    
    carteles.push({
      id: `legacy-${index + 1}`,
      attributes: {
        Year: `${yearMatch[1]}-01-01`,
        Diseno: designerText,
        Cartel: {
          data: [{
            attributes: {
              url: imgUrl,
              alternativeText: `Cartel ${yearMatch[1]}`
            }
          }]
        }
      }
    });
  });
  
  return { data: carteles };
} 