import React from 'react';
import { Link, Outlet } from 'react-router-dom';

const Hemeroteca = () => {
  return (
    <div className="container">
      <nav>
     
      </nav>
      <Outlet />
    </div>
  );
}

export default Hemeroteca;
