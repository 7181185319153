import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PostsList = () => {
  const [posts, setPosts] = useState([]);
  const blogId = '1555415857002703612';  // Replace with your Blogger Blog ID
  const apiKey = 'AIzaSyBCuUWquNrat7XA0oeYfexifEjP5V7wRGE';  // Replace with your API key

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/blogger/v3/blogs/${blogId}/posts?key=${apiKey}`
        );
        setPosts(response.data.items);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };
    fetchPosts();
  }, []);

  return (
    <div className="posts-list">
      <h2>Entradas recientes</h2>
      {posts.map((post) => (
        <div key={post.id} className="post-item">
          <h3>{post.title}</h3>
          <p>{new Date(post.published).toLocaleDateString()}</p>
          <div dangerouslySetInnerHTML={{ __html: post.content.slice(0, 200) }} />
          <a href={post.url} target="_blank" rel="noopener noreferrer">
               Leer más
          </a>
        </div>
      ))}
    </div>
  );
};

export default PostsList;
