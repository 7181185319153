import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const CDContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const CDCard = styled.div`
  background: #f9f5ff;
  border-left: 4px solid #8a2be2;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 0 8px 8px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Title = styled.h2`
  color: #333;
  font-size: 1.8rem;
  margin-bottom: 1rem;
`;

const ImagesContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin: 1rem 0;
  flex-wrap: wrap;

  img {
    width: 300px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }
`;

const AudioPlayer = styled.div`
  margin: 1rem 0;
  
  audio {
    width: 100%;
    margin-top: 1rem;
  }
`;

const PageHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;
  
  h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  p {
    color: #666;
    font-size: 1.1rem;
  }
`;

const CD = () => {
  const [cds, setCDs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCDs = async () => {
      try {
        const token = process.env.REACT_APP_API_TOKEN;
        const response = await axios.get(`${apiUrl}/api/cds?populate=*`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log(response.data.data);
        setCDs(response.data.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching CDs:', err);
        setError('Error al cargar los CDs');
        setLoading(false);
      }
    };

    fetchCDs();
  }, [apiUrl]);

  if (loading) return <div>Cargando CDs...</div>;
  if (error) return <div>{error}</div>;

  return (
    <CDContainer>
      <PageHeader>
        <h1>CD de les comadres</h1>
        <p>Descubre nuestra música y grabaciones</p>
      </PageHeader>

      {cds.map((cd) => (
        <CDCard key={cd.id}>
          <Title>{cd.attributes.Titulo}</Title>
          
          {/* Imágenes */}
          <ImagesContainer>
            {cd.attributes.Imagen?.data && cd.attributes.Imagen.data.map((imagen, index) => (
              <img 
                key={index}
                src={`${apiUrl}${imagen.attributes.url}`}
                alt={`${cd.attributes.Titulo} - Imagen ${index + 1}`}
              />
            ))}
          </ImagesContainer>

          {/* Reproductor de Audio */}
          <AudioPlayer>
            {cd.attributes.Cd?.data && cd.attributes.Cd.data.map((audio, index) => (
              <div key={index}>
                <h3>Escuchar CD:</h3>
                <audio controls>
                  <source src={`${apiUrl}${audio.attributes.url}`} type={audio.attributes.mime} />
                  Tu navegador no soporta el elemento de audio.
                </audio>
              </div>
            ))}
          </AudioPlayer>
        </CDCard>
      ))}

        <PageHeader>
        <p>Este CD es para recoger y recordar las "coplas" que en nuestra fiesta "Jueves de Comadres" cantamos en honor y deshonor de Comadres, Felpeyos y Babayos. Sean ustedes, amigas y amigos oyentes, benévolos con nuestro esfuerzo y no tomen a mal nuestras chanzas, "estamos en carnaval".</p>
        <p>Grabación y edición con la colaboración del Instituto Asturiano de la Mujer. Gijón. 2001</p>
      </PageHeader>
    </CDContainer>
  );
};

export default CD;
