import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTimes, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

// Importar todas las imágenes dinámicamente
function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('./fotos', false, /\.(png|jpe?g|svg)$/));

const GaleriaContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
`;

const GaleriaHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;
  
  h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  p {
    color: #666;
    font-size: 1.1rem;
  }
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
`;

const ImageItem = styled(motion.div)`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  aspect-ratio: 1;
  background: #f5f5f5;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, 0.7));
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
  }

  &:hover::before {
    opacity: 1;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;

  ${ImageItem}:hover & {
    transform: scale(1.1);
  }
`;

const ImageTitle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  color: white;
  font-size: 1rem;
  z-index: 2;
  transform: translateY(100%);
  transition: transform 0.3s ease;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);

  ${ImageItem}:hover & {
    transform: translateY(0);
  }
`;

const FullscreenOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const FullscreenImage = styled(motion.img)`
  max-width: 90%;
  max-height: 90vh;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
`;

const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  font-size: 2rem;
  padding: 1rem;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease;
  z-index: 1001;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  &.prev { left: 20px; }
  &.next { right: 20px; }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  font-size: 1.5rem;
  padding: 1rem;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease;
  z-index: 1001;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: rotate(90deg);
  }
`;

const ImageCounter = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
`;

const Galeria = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageArray = Object.entries(images);

  const openFullscreen = useCallback((image, index) => {
    setSelectedImage(image);
    setCurrentIndex(index);
  }, []);

  const closeFullscreen = useCallback((e) => {
    e.stopPropagation();
    setSelectedImage(null);
  }, []);

  const navigateImage = useCallback((direction) => (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + direction;
      if (newIndex >= imageArray.length) return 0;
      if (newIndex < 0) return imageArray.length - 1;
      return newIndex;
    });
  }, [imageArray.length]);

  const getImageTitle = (filename) => {
    return filename.replace(/_/g, ' ').replace(/\.[^/.]+$/, '');
  };

  return (
    <GaleriaContainer>
      <GaleriaHeader>
        <h1>Nuestra Galería</h1>
        <p>Explora nuestra colección de momentos memorables</p>
      </GaleriaHeader>

      <ImageGrid>
        {imageArray.map(([key, src], index) => (
          <ImageItem
            key={index}
            onClick={() => openFullscreen(src, index)}
            whileHover={{ y: -5 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <StyledImage src={src} alt={getImageTitle(key)} />
            <ImageTitle>{getImageTitle(key)}</ImageTitle>
          </ImageItem>
        ))}
      </ImageGrid>

      <AnimatePresence>
        {selectedImage && (
          <FullscreenOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeFullscreen}
          >
            <NavigationButton className="prev" onClick={navigateImage(-1)}>
              <FaChevronLeft />
            </NavigationButton>

            <FullscreenImage
              src={imageArray[currentIndex][1]}
              alt={getImageTitle(imageArray[currentIndex][0])}
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            />

            <NavigationButton className="next" onClick={navigateImage(1)}>
              <FaChevronRight />
            </NavigationButton>

            <CloseButton onClick={closeFullscreen}>
              <FaTimes />
            </CloseButton>

            <ImageCounter>
              {currentIndex + 1} / {imageArray.length}
            </ImageCounter>
          </FullscreenOverlay>
        )}
      </AnimatePresence>
    </GaleriaContainer>
  );
};

export default Galeria;
