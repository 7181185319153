import styled from 'styled-components';

const PageWrapper = styled.div`
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.06);
  
  @media (max-width: 768px) {
    padding: 1.5rem;
    margin: 1rem;
    border-radius: 16px;
  }
`;

export default PageWrapper; 