export function parseFelpeyosHTML(htmlContent) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const felpeyos = [];
  
  // Encontrar la sección de Felpeyos (después del título "FELPEYOS 1990 - 2024")
  const felpeyosCells = Array.from(doc.querySelectorAll('td[align="center"][valign="top"]'))
    .filter(cell => {
      const yearLink = cell.querySelector('a');
      return yearLink && yearLink.textContent.includes('Año') && 
             cell.querySelector('img[src*="felpeyu"]');
    });
  
  felpeyosCells.forEach((cell, index) => {
    const yearLink = cell.querySelector('a');
    const yearText = yearLink.textContent;
    const yearMatch = yearText.match(/(\d{4})/);
    if (!yearMatch) return;
    
    const imgLink = cell.querySelector('img');
    if (!imgLink) return;
    let imgUrl = imgLink.getAttribute('src');
    
    // Asegurarse de que la URL comience con /
    if (!imgUrl.startsWith('/')) {
      imgUrl = '/' + imgUrl;
    }
    
    felpeyos.push({
      id: `felpeyo-${index + 1}`,
      attributes: {
        Year: `${yearMatch[1]}-01-01`,
        Autor: "Jorge Enrique Maojo Vilasboas",
        Imagen: {
          data: [{
            attributes: {
              url: imgUrl,
              alternativeText: `Felpeyo ${yearMatch[1]}`
            }
          }]
        }
      }
    });
  });
  
  return { data: felpeyos };
} 